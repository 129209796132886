<script setup lang="ts">
import { useForm, usePage, Head } from '@inertiajs/vue3'
import { Mail, Lock } from '@vicons/tabler'

defineProps<{
    canResetPassword?: boolean;
    status?: string;
}>()

const agencyDomain = computed(() => usePage().props.agencyDomain)

const waiting = ref(false)
const form = useForm({
    email: '',
    password: '',
    remember: false,
})

const formRecover = useForm({
    email: ''
})

const $toast = useToast()

const loginRecover = ref('login')

const submitRecover = () => {
    waiting.value = true
    formRecover.post(route('password.email'), {
        onSuccess: () => {
            $toast.success('Te enviamos um e-mail com instruções para prosseguir com a recuperação de sua senha!')
        },

        onError: (e) => {
            const error = Object.values(e)[0]
            $toast.error(error)
        },

        onFinish: () => {
            waiting.value = false
        }
    })
}

const submit = () => {
    form.post(route('login'), {
        onError: (e) => {
            const error = Object.values(e)[0]
            $toast.error(error)
        },
        onFinish: () => {
            form.reset('password')
        },
    })
}

</script>

<template>
    <Head>
        <title v-if="agencyDomain">{{ agencyDomain.company_name }} - Login</title>
        <title v-else>WhatsCompany - Login</title>
    </Head>
    
    <WaitingModal v-if="waiting" />
    <section class="tw-w-full tw-min-h-screen tw-bg-[#F5F5F9] tw-flex tw-justify-center tw-items-center">
        <section class="login-outer">
            <section class="login-inner">
                <div class="tw-w-full tw-inline-flex tw-justify-center">
                    <h2 class="tw-text-[40px] tw-font-bold" v-if="!agencyDomain">
                        <span class="tw-text-blue-wc">Whats.</span>
                        <span class="tw-text-green-wc">Company</span>
                    </h2>
                    <div v-else class="tw-w-full tw-flex tw-justify-center">
                        <img 
                            v-if="agencyDomain.logo"
                            :src="agencyDomain.logo" 
                            class="tw-max-w-[80%] tw-inline-flex"
                        >

                        <h2 class="tw-text-[40px] tw-font-bold" v-else>
                            <span class="tw-text-blue-wc">{{ agencyDomain.company_name }}</span>
                        </h2>
                    </div>
                </div>
                <span class="tw-w-full tw-flex tw-font-bold tw-text-slate-700" v-if="loginRecover=='recover'">Recuperar senha</span>
                
                <form
                    @submit.prevent="submitRecover"
                    class="tw-w-full tw-gap-3 tw-flex tw-flex-wrap"
                    v-if="loginRecover == 'recover'"
                >
                    <BaseInput
                        :icon="Mail"
                        v-model="formRecover.email"
                        placeholder="Informe seu e-mail"
                        :outlined="true"
                        class="tw-bg-white"
                    />

                    <p class="tw-w-full tw-flex tw-justify-center tw-text-slate-600">
                        Sabe seu login?
                        <span
                            @click="loginRecover='login'"
                            class="tw-ml-1 tw-text-blue-wc tw-cursor-pointer"
                        >
                            Logar
                        </span>
                    </p>
                    <AssetsButton
                        class="tw-w-full"
                        type="submit"
                    >
                        Recuperar senha
                    </AssetsButton>
                </form>

                <form
                    @submit.prevent="submit"
                    class="tw-w-full tw-gap-3 tw-flex tw-flex-wrap"
                    v-if="loginRecover == 'login'"
                >
                    <BaseInput
                        :icon="Mail"
                        v-model="form.email"
                        placeholder="Informe seu e-mail"
                        :outlined="true"
                        class="tw-bg-white"
                    />

                    <BaseInput
                        :icon="Lock"
                        v-model="form.password"
                        placeholder="Informe sua senha"
                        :outlined="true"
                        class="tw-bg-white"
                        type="password"
                    />

                    <p class="tw-w-full tw-flex tw-justify-center tw-text-slate-600">
                        Esqueceu sua senha?
                        <span
                            @click="loginRecover='recover'"
                            class="tw-ml-1 tw-text-blue-wc tw-cursor-pointer"
                        >
                                Recuperar senha
                        </span>
                    </p>
                    <AssetsButton
                        class="tw-w-full"
                        type="submit"
                    >
                        Entrar
                    </AssetsButton>
                </form>
            </section>
        </section>
    </section>
</template>

<style scoped>

.login-outer{
    @apply tw-w-[400px] tw-relative;
}
.login-inner {
    @apply tw-relative tw-z-10 tw-w-full tw-rounded-md tw-shadow-md tw-p-6 tw-bg-white tw-gap-3 tw-flex tw-flex-wrap;
}

.login-outer:before {
    width: 148px;
    height: 148px;
    content: " ";
    position: absolute;
    top: -40px;
    right: -40px;
    background: url(@images/login-pattern-top.svg);
    z-index:1;
}

.login-outer:after {
    width: 243px;
    height: 240px;
    content: " ";
    position: absolute;
    bottom: -68px;
    left: -46px;
    background: url(@images/login-pattern-bottom.svg);
}
</style>
